.archives_up[data-v-76b32762] {
  width: 96%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.bigimg[data-v-76b32762] {
  margin-top: 19px;
}
.bigimg button[data-v-76b32762] {
  transform: translateY(-10px);
  margin-left: 20px;
  cursor: pointer;
}
.bigimg input[data-v-76b32762] {
  width: 98px;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.pic[data-v-76b32762] {
  display: flex;
}
.del_btn[data-v-76b32762] {
  position: relative;
  top: -97px;
  left: 94%;
  padding-right: 10px;
  color: red;
  cursor: pointer;
}